/* RESET */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;

    font-family: 'Sniglet', cursive;
    font-size: calc(20px + .8vw);
    letter-spacing: .05vw;

    vertical-align:baseline;
    background:transparent;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: white;
    text-decoration: none;
}

.Hide {
    display: none;
    color: rgba(0,0,0,0);
    background: rgba(0,0,0,0);
}

.Content {
    position: fixed;
    top: 0%;
    display: flex;
    height: 100%;
    width: 100%;
    background: #FC9190;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.Grid {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.GridItem {
    margin-left: auto;
    margin-right: auto;
}

.Button {
    font-family: 'Sniglet', cursive;
    transition: all 0.4s;
    border-radius: 50px;
    background: rgb(255,255,255);
    color: #70C1E8;
    border: 5px solid #70C1E8; 
    font-size: calc(10px + 2vmin);
    padding-left: 3vw;
    padding-right: 3vw;
        height: 2em;

}

.Button:focus {
    outline:0;
}

.Button:hover {
    transform: scale(1.1);
}

.Button:active {
    background: rgba(0,0,0,0);
    color: rgb(255,255,255);
}

.Button.CTA {
    /*margin: 2vmin;*/
}

.Button.CTA.Hide {
    display: none;
    color: rgba(0,0,0,0);
    background: rgba(0,0,0,0);
}

.Button.Submit {
    background: rgb(255,255,255);
}

.Button.Submit.Hide {
    transition: .5s;
    color: rgba(0,0,0,0);
    background: rgba(0,0,0,0);
}

#AppStoreBadge {
    height:1.5em;
    margin-bottom: .1em;
}

#SignupBox {

}

/*MAIL CHILD EMAIL INPUT*/
#mce-EMAIL {
    font-family: 'Sniglet', cursive;
    font-size: 1em;
    margin-top: 15px;
    margin-bottom: 15px;
    text-indent: 10px;
    border-width: 0px;
    /*width: 100%;*/
    height: 2em;
    border-radius: 50px;
    outline: 0;
    color: rgba(1,1,1, 1);
}

/* MAILCHIMP VALIDATION RESPONSE STYLE*/
.response{
    margin-bottom: 20px;    
}

#mce-error-response {
    font-size: .7em;
    color: rgb(210,210,210);
}

#mce-error-response {
    font-size: .7em;
    color: rgb(240,240,240);
}

#mce-success-response {
    font-size: .7em;
    color: rgb(255,255,255);
}

.mce_inline_error{
    font-size: .7em;
    border-radius: 25px;
}

#mc-embedded-subscribe-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;    
}

#SignupFormDescription {
    font-size: 1em;
    color: rgb(255,255,255);
    letter-spacing: 1.5px;
}


footer#BarBottom {
    position: fixed;
    padding: 10px;
    bottom: 0%;
    width: 100%;
    height: 80px;
    z-index: 1;
    background: rgba(0,0,0,0);
    color: rgb(255,255,255);
}

#SectionTop {
    margin: auto;
}

#SectionBottom {
    margin: auto;
}

#KeyImage {
	width: 100vmin;	
    /*margin-bottom: 1vmin;*/
}

#LogoPuppetoon {
	width: 60vmin;
	margin-left: auto;
	margin-right: auto;
}

#Tagline {
    text-align: center;
	color: #FFF0F5;
    font-size: calc(10px + 2vmin);
}

#CTATagline {
    color: #FFF0F5;
    font-size: calc(7px + 1.5vmin);
    margin-bottom: 1vmin;
    text-align: center;
}

#SocialFollowBar {
    position: fixed;
    left: 10px;
    min-height: 30px;
    height: 5%;
    bottom: 10px;
}

.SocialFollowIcons {
    height: 100%;
    filter: opacity(50%);
    /*filter: saturate(0%);*/
}

.SocialFollowIcons:hover {
    filter: opacity(100%);
}

#FooterCuteTechLogo {
    position: fixed;
    right: 10px;
    min-height: 30px;
    height: 5%;
    bottom: 10px;
    filter: opacity(65%);
}

#FooterCuteTechLogo:hover {
    filter: brightness(1500%);
}

.UnderlinedLink {
    text-decoration: underline;
}